/* eslint eqeqeq: "off" */
import React, { useState, memo } from "react"
import { withAuthenticator } from "@aws-amplify/ui-react"
import connectStore from "../state/connectStore"
import Layout from "../ui/Layout"
import {
  Button,
  Input,
  Progress,
} from "semantic-ui-react"
import QRCodeLoader from "../ui/QRCodeLoader"
import JSZip from "jszip"
import { saveAs } from "file-saver"
import XLSX from "xlsx"
import QRCode from "qrcode"
import { fromOrderIdToUrl } from "../util/OrderUtil"
import Color from "../style/Color"

const num = (n, def=1) => isNaN(n) || n == 0 ? def : parseFloat(n);

const getTimestamp = () => {
  const date = new Date();
  const options = {
    year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", hour12: true, minute: "2-digit", second: "2-digit"
  }
  const timestamp = date.toLocaleTimeString("en-us", options)
    .replaceAll("/", "-")
    .replaceAll(", ", "-")
    .replaceAll(":", "")
    .replaceAll(" ", "-")
    .replaceAll("-PM", "")
    .replaceAll("-AM", "");
  return timestamp;
};

const QRList = memo(({ versionId, ROWS, QR_SIZE, addOrderId }) => {
  if(ROWS == 0 || QR_SIZE == 0) {
    return null;
  }
  return (
    [...Array(ROWS)].map((_, i) => (
      <QRCodeLoader key={i} hide versionId={versionId} className="single-canvas" size={QR_SIZE} addOrderId={addOrderId}/>
    ))
  );
});

const Page = ({ location, qrSheetProps={}, setQrSheetProps }) => {
  const { ppi=300, rows=1, qrSizeIn=0.86 } = qrSheetProps;

  // Calculate all our sizes based on our state
  const PPI = num(ppi, 300);
  const ROWS = num(rows, 1);
  const QR_SIZE_IN = num(qrSizeIn, 0.86);

  const QR_SIZE = Math.floor(PPI * QR_SIZE_IN);

  const [versionId, setVersionId] = useState(0);
  const [loading, setLoading] = useState(false);
  const tempOrderIds = [];

  const generateQRZip = codes => {
    const zip = new JSZip();
    const data = [["File Name", "Alphanumeric Code"]];
    processNextQRCode(zip, data, codes, 0);
  };

  // Process a single index
  const processNextQRCode = (zip, data, codes, index) => {
    const qrcode = codes[index];
    const qrcodeFileName = qrcode + ".png";
    data.push([qrcodeFileName, qrcode]);
    
    // Given our code, create the url, generate a qr code, and add it to a zip
    const url = fromOrderIdToUrl(qrcode);
    console.log("URL: " + url);
    QRCode.toDataURL(url, {
      errorCorrectionLevel: "M",
      type: "image/png",
      width: QR_SIZE,
    }, (err, dataURL) => { 
      // Add our image file to the zip
      zip.file(qrcodeFileName, dataURL.replace(/^data:image\/(png|jpg);base64,/, ""), {base64: true});

      // Increment our index and see where we're at
      index++;

      // If we still have more codes to process, process the next one.
      if(index < codes.length) {
        processNextQRCode(zip, data, codes, index);
        return;
      }

      // Create a unique label for our zip and matching mapping file
      const label = "-" + getTimestamp();

      // Now create our spreadsheet and add it to the zip
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Mapping");
      const binaryWorkbook = XLSX.write(workbook, {
        bookeType: "xlsx",
        bookSST: true,
        type: "binary",
      });
      zip.file("000-mapping" + label + ".xlsx", binaryWorkbook, { binary: true });

      // Now auto-download the zip
      zip.generateAsync({type: "blob"}).then(zBlob => {
        saveAs(zBlob, "qrcodes" + label + ".zip");
        setLoading(false);
      }, alert);
    });
  }

  const addOrderId = id => {
    tempOrderIds.push(id);
    if(tempOrderIds.length == ROWS) {
      generateQRZip([...tempOrderIds]);
    }
  };

  return (
    <Layout location={location} validate={false} showOrder={false}>
      <div>
        <Input type="number" label="Pixels Per Inch (PPI)" value={ppi} onChange={(e, { value }) => setQrSheetProps({ ppi: value })} />
        <br/><br/>
        <Input type="number" label="QR Size (in.)" value={qrSizeIn} onChange={(e, { value }) => setQrSheetProps({ qrSizeIn: value })} />
        <br/><br/>
        <Input type="number" label="Quantity" value={rows} onChange={(e, { value }) => setQrSheetProps({ rows: value })} />
      </div>
      <br/>
      <div className="super-centered">
        <Button disabled={loading} loading={loading} color={Color.accent} onClick={() => {
          setVersionId(versionId+1);
          setLoading(true);
        }}>Download QR Zip</Button>
      </div>
      <br/><br/>
      {loading && <Progress percent={100} active color={Color.accent}>Please Wait</Progress>}
      <div className="hide">
        <QRList ROWS={ROWS} versionId={versionId} QR_SIZE={QR_SIZE} addOrderId={addOrderId}/>
      </div>
    </Layout>
  );
};

export default withAuthenticator(connectStore(Page));
