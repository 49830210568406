/* eslint eqeqeq: "off" */
import React, { useState, useEffect } from "react"
import QRCode from "qrcode.react"
import { fromOrderIdToUrl, createOrderId } from "../util/OrderUtil"

const QRCodeLoader = ({ versionId, size, addOrderId, hide=false, id=null, className=null }) => {
  const origVersionId = useState(versionId)[0];
  const [renderedTwice, setRenderedTwice] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [lastVersionId, setLastVersionId] = useState(versionId);
  const url = fromOrderIdToUrl(orderId);

  useEffect(() => {
    if(versionId != lastVersionId) {
      setLastVersionId(versionId);
      setOrderId(null);
      return;
    }
    if(lastVersionId == origVersionId) {
      return;
    }
    if(!renderedTwice) {
      setRenderedTwice(true);
    }
    if(orderId == null) {
      createOrderId(newOrderId => {
        setOrderId(newOrderId);
        addOrderId(newOrderId);  
      });
    }
  }, [renderedTwice, orderId, setOrderId, versionId, lastVersionId, setLastVersionId, addOrderId, origVersionId]);

  if(hide) {
    return null;
  }

  return (
    <QRCode id={id} className={className + " qr-" + (orderId == null ? "none" : orderId)} value={url} size={size} level="M"/>
  );
}
  
export default QRCodeLoader;
